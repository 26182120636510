.footer, .info {
    color: white;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer {
    background-color: #343a40;
    width: 100%;
    height: 50px;
    padding: 20px;
}

.info {
    background-color: rgba(0, 0, 0, 0.7);
    height: 30px;
    padding: 10px;
    border-radius: 5px;
}

@media (max-width: 768px) {
    .footer {
        height: auto;
        padding: 10px;
    }

    .info {
        height: auto;
        padding: 5px;
    }
}