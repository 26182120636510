.header {
    background-color: #343a40;
    padding: 10px 20px;
}

.header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
}

.site-name {
    font-size: 24px;
    font-weight: bold;
    color: white;
    display: flex;
    align-items: center;
}

.site-name a {
    color: white;
    text-decoration: none;
}

.site-name a:hover {
    text-decoration: underline;
}

.nav {
    display: flex;
    align-items: center;
}

.nav-list {
    list-style-type: none;
    display: flex;
    margin: 0;
    padding: 0;
}

.nav-list li {
    position: relative;
    margin-right: 20px;
}

.nav-list li::after {
    content: '';
    position: absolute;
    right: -10px;
    top: 50%;
    transform: translateY(-50%);
    height: 1.5rem;
    border-right: 1px solid white;
}

.nav-list li:last-child::after {
    display: none;
}

.nav-list li a {
    color: white;
    text-decoration: none;
    font-weight: bold;
    padding: 5px 10px;
    transition: color 0.3s ease, background-color 0.3s ease;
    border-radius: 4px;
}

.nav-list li a:hover,
.nav-list li.active a {
    color: rgb(25, 25, 25);
    background-color: rgba(255, 255, 255, 0.2);
}

.discord-button {
    display: flex;
    align-items: center;
    background-color: #9daad8; 
    color: white;
    padding: 10px;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
}

.discord-button:hover {
    background-color: gray; 
}

.discord-logo {
    width: 24px;
    height: 16px;
    margin-right: 8px;
}

.skyali-logo {
    height: 64px;
    width: 64px;
    margin-right: 8px;
    vertical-align: middle;
}

@media (max-width: 768px) {
    .header-content {
        flex-direction: column;
        align-items: center;
    }

    .nav-list {
        flex-direction: column;
        align-items: center;
    }

    .nav-list li {
        margin-right: 0;
        margin-bottom: 10px;
    }

    .nav-list li::after {
        display: none;
    }
}