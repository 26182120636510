.section-title {
    text-align: center;
    width: 100%;
    font-size: 2em;
    margin: 30px 0;
}

.main-container {
    grid-template-columns: 350px 350px 350px;
}

#toggles {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

#toggles label {
    background-color: #808080;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 180px;
    text-align: center;
    display: block;
    margin: 0;
}

#toggles input:checked + label {
    background-color: #555;
}

#toggles input {
    display: none;
}

.language-filter {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.language-filter select {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    width: 150px;
}

.list-container {
    display: grid;
    gap: 20px;
    justify-content: center;
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
}

.item-thumbnail {
    display: flex;
    gap: 10px;
    width: 100%;
    justify-content: center;
    max-width: 900px;
    margin: 0 auto;
}

.thumbnail {
    width: 65%;
    height: auto;
    border-radius: 5px;
    flex-shrink: 0;
}

.thumbnail-info-right {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 35%;
    flex-shrink: 0;
    padding-right: 10px;
}

.info-box {
    width: 100%;
}

.tag-hover-area {
    position: relative;
}

.tag-content {
    position: absolute;
    top: -15px;
    left: -100%;
    background-color: rgba(0, 0, 0, 0.95);
    padding: 8px 12px;
    border-radius: 5px;
    z-index: 1000;
    min-width: 200px;
    opacity: 0;
    transition: opacity 0.2s;
    display: none;
}

.tag-hover-area:hover .tag-content {
    display: flex;
    flex-direction: column;
    gap: 5px;
    opacity: 1;
}

.tag {
    padding: 5px 8px;
    background-color: rgba(255, 255, 255, 0.1);
}

.youtube-item .item-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.youtube-item .item-footer {
    width: 100%;
    margin-bottom: 15px;
    order: 1;
}

.youtube-item .thumbnail {
    width: 100%;
    max-width: 480px;
    border-radius: 5px;
    margin: 0 auto;
    order: 2;
}

.youtube-item h4 {
    width: 100%;
    text-align: center;
    margin: 15px 0;
    order: 3;
}

.youtube-item .icon-name {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: flex-start;
}

@media (max-width: 768px) {
    .main-container {
        grid-template-columns: 1fr;
    }

    .item-thumbnail {
        flex-direction: column;
    }

    .thumbnail {
        width: 100%;
    }

    .thumbnail-info-right {
        width: 100%;
        padding-right: 0;
    }
}