.privacy-notice {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    max-width: 500px;
    background-color: #343a40;
    color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1000;
}

.cookie-content {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.cookie-icon {
    font-size: 2rem;
    margin-right: 15px;
}

.cookie-text p {
    margin: 0;
    font-size: 14px;
    text-align: left;
}

.cookie-text a {
    color: #f8f9fa;
    text-decoration: underline;
}

.buttons {
    display: flex;
    gap: 10px;
}

.accept-button, .reject-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 14px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.accept-button:hover {
    background-color: #0056b3;
}

.reject-button {
    background-color: #dc3545;
}

.reject-button:hover {
    background-color: #c82333;
}

@media (max-width: 768px) {
    .privacy-notice {
        width: 90%;
        padding: 10px;
    }

    .cookie-content {
        flex-direction: column;
        align-items: flex-start;
    }

    .cookie-icon {
        margin-bottom: 10px;
    }

    .buttons {
        flex-direction: column;
        width: 100%;
    }

    .accept-button, .reject-button {
        width: 100%;
        padding: 10px;
    }
}