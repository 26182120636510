.title {
    text-align: center;
    margin-bottom: 20px;
}

.error-message {
    text-align: center;
}

.filters {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
}

#tags {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: center;
}

#tags label {
    background-color: #555;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

#tags input {
    display: none;
}

#tags input:checked + label {
    background-color: rgb(180, 180, 180);
}

.list-container {
    display: grid;
    gap: 20px;
    justify-content: center;
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

.item {
    background-color: rgba(255, 255, 255, 0.2); 
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    transition: transform 0.2s, background-color 0.3s;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.tags {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 5px;
    margin-bottom: 10px;
}

.tag {
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    margin: 2px;
    display: inline-block;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.item-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.icon-name {
    display: flex;
    align-items: center;
    gap: 10px;
}

.icon-name img {
    margin-right: 10px;
}

.item h3 {
    font-size: 1.2em;
    margin: 10px 0 5px;
    text-align: center;
}

.item p {
    font-size: 0.9em;
    margin: 5px 0;
    text-align: center;
}

.item:hover {
    transform: scale(1.05);
    background-color: rgba(255, 255, 255, 0.4);
}

.error-message {
    color: red;
    font-weight: bold;
}

.modal {
    display: none;
    position: fixed;
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.7);
}

.modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 600px;
    border-radius: 5px;
    position: relative;
    z-index: 3;
}

.icon-name {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

@media (max-width: 768px) {
    .modal-content {
        width: 90%;
    }

    .list-container {
        grid-template-columns: 1fr;
    }

    .item {
        width: 100%;
    }
}