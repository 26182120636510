.sort-options {
    margin-top: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.sort-options select, .sort-filter select {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    width: 150px;
}

.item-content, .thumbnail-info-right, .thumbnail-details-bottom, .icon-name {
    display: flex;
    gap: 10px;
}

.item-content {
    flex-direction: column;
    width: 100%;
}

.item-thumbnail {
    width: 100%;
    justify-content: left;
    max-width: 440px;
    margin: 0 auto;
}

.thumbnail-img {
    width: 65%;
    height: auto;
    border-radius: 5px;
    object-fit: cover;
}

.thumbnail-details-bottom {
    margin-top: 10px;
    width: 100%;
    justify-content: left;
    padding-left: 20px;
}

.thumbnail-details-bottom .info {
    width: auto;
    padding: 8px 12px;
    white-space: nowrap;
    display: inline-flex;
    align-items: center;
}

.details {
    margin-top: 10px;
}

.item h3, .item h4 {
    font-family: Arial, Times, serif;
    margin: 5px 0;
}

.main-container {
    grid-template-columns: 350px minmax(350px, 700px) 350px;
}

.video-grid {
    margin-top: 20px;
    display: grid;
    gap: 20px;
    justify-content: center;
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
}

@media (max-width: 768px) {
    .main-container {
        grid-template-columns: 1fr;
    }

    .item-thumbnail {
        flex-direction: column;
    }

    .thumbnail-img {
        width: 100%;
    }
}