* {
    font-family: Arial, Times, serif;
}

.title, .error-message {
    text-align: center;
}

.title {
    margin-bottom: 20px;
}

.error-message {
    color: red;
    font-weight: bold;
}

.main-container {
    display: grid;
    gap: 15px;
    max-width: 1600px;
    padding: 0 20px;
    margin: 20px auto;
    align-items: start;
    justify-content: center;
}

.left-controls, .filter-info {
    padding: 15px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
}

.left-controls {
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
}

.filter-info p {
    margin: 5px 0;
    white-space: pre-line;
}

.search-container {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.title-search-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 800px;
    padding: 0;
    text-align: center;
}

.title-search-container input[type="text"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
}

.item {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    padding: 15px;
    cursor: pointer;
    transition: transform 0.2s, background-color 0.3s;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.item:hover {
    transform: scale(1.02);
    background-color: rgba(255, 255, 255, 0.4);
}

.info {
    display: block;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 8px 20px;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    min-width: 120px;
}

#searchBar {
    padding: 10px;
    width: 80%;
    max-width: 300px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

#containerSearchBar {
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
    max-width: 500px;
}

@media (max-width: 768px) {
    .main-container {
        grid-template-columns: 1fr;
    }

    .left-controls {
        width: 100%;
    }

    .item {
        width: 100%;
    }
}