.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-content {
    width: 400px;
    background: linear-gradient(135deg, #2c2f33, #23272a);
    color: white;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    font-family: Arial, sans-serif;
    position: relative;
}

.modal-header {
    display: flex;
    align-items: center;
    gap: 10px;
}

.modal-icon {
    width: 50px;
    height: 50px;
    border-radius: 5px;
}

.modal-title {
    font-size: 1.5rem;
    margin: 0;
    flex: 1;
    text-align: center;
}

.close-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: white;
}

.modal-body {
    margin: 20px 0;
}

.modal-description {
    margin-bottom: 15px;
    font-size: 0.9rem;
    color: #b9bbbe;
}

.modal-features {
    list-style: none;
    padding: 0;
}

.modal-features li {
    margin: 8px 0;
    display: flex;
    align-items: center;
    gap: 10px;
}

.feature-icon {
    font-size: 1.2rem;
}

.modal-footer {
    text-align: center;
    margin-top: 20px;
}

.cta-button {
    text-decoration: none;
    color: white;
    background: #7289da;
    padding: 10px 20px;
    border-radius: 5px;
    font-weight: bold;
    transition: background 0.3s;
    display: inline-block;
    cursor: pointer;
}

.cta-button:hover {
    background: #5b6eae;
}