.home {
    text-align: center;
    margin-top: 20px;
    color: white;
}

.about-us {
    text-align: center;
    margin-top: 40px;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
}

.team {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 40px;
    flex-wrap: wrap;
}

.member {
    background-color: rgba(255, 255, 255, 0.1);
    padding: 20px;
    border-radius: 10px;
    width: 300px;
    text-align: left;
}

.member-image {
    width: 100%;
    border-radius: 10px;
    margin-bottom: 10px;
}

.cookies-info {
    margin-top: 40px;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
}

.cookies-info h2 {
    margin-bottom: 10px;
}

@media (max-width: 768px) {
    .team {
        flex-direction: column;
        align-items: center;
    }

    .member {
        width: 100%;
    }
}