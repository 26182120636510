* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; 
}

.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-image: url('../../assets/images/dark-background.avif');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.content {
  flex: 1;
  padding: 20px;
  color: white;
}

.header {
  flex-shrink: 0;
  padding: 10px 20px;
  background-color: rgba(52, 58, 64, 0.9);
  color: white;
}

.footer {
  flex-shrink: 0;
  padding: 10px 20px;
  background-color: rgba(52, 58, 64, 0.9);
  color: white;
  text-align: center;
}

@media (max-width: 768px) {
  .content {
    padding: 10px;
  }

  .header, .footer {
    padding: 10px;
  }
}